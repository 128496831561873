import WrappedTabs, { IWrappedTabChild } from '@/components/ui/WrappedTab';
import { isLoggedIn } from '@/state/userInfo';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import HomepageBannerBox from '@/components/features/common/WelcomeCarousel';
import { useTranslation } from 'next-i18next';
import { useAtom, useAtomValue } from 'jotai';
import dynamic from 'next/dynamic';
import { APP_FIXED_HEADER_HEIGHT } from '@/constants/global';
import queryKeysFactory from '@/reactQueryKeys/keysFactory';
import { fetchFavouriteGames, fetchGameVisitList } from '@/apis/casino/games';
import UserGameList from '@/components/pages/casino/UserGameList';
import { gameListSelector } from '@/state/globalSettings';
import {
  baccaratGamesFilter,
  blackjackGamesFilter,
  originalGamesFilter,
  liveCasinoGamesFilter,
  rouletteGamesFilter,
  slotsGamesFilter,
  SortByEnum,
  tableGamesFilter,
  useCasinoTitle,
  useCasinoDescription,
} from '@/components/pages/casino/helper';
import GameCardCarousel from '@/components/features/common/GameCardCarousel';
import queryClient from '@/reactQueryKeys/queryClient';
import React, { useEffect, useMemo } from 'react'; // const renderBannerItem = (
import {
  CasinoBaccarat,
  CasinoBlackjack,
  CasinoFavorites,
  CasinoLive,
  CasinoOriginals,
  CasinoRecent,
  CasinoRelease,
  CasinoRoulette,
  CasinoSlots,
  CasinoTableGames,
} from '@crownbit/icons';
import useTheme from '@/theme/useTheme';
import GameSearchWithFilter from '@/components/pages/casino/GameSearchWithFilter';
import useUpdateUserInfoAsync, {
  UPDATE_USER_INFO_MODE,
} from '@/hooks/biz/useUpdateUserInfoAsync';
import { isTelegramMiniApp } from '@/utils/checkRuntime';
import mapFavoriteOrRecentToLocal from '@/utils/biz/mapFavoriteOrRecentToLocal';
import CasinoHomepageBanners from '@/components/biz/casino/CasinoHomepageBanners';
import { Favorite, Restore, Home } from '@mui/icons-material';
import GameProviders from './GameProviders';
import AdditionText from './AdditionText';
import Head from 'next/head';
import { renderMetaDescription, renderMetaTitle } from '@/helper/seo';

const CasinoPageLiveWins = dynamic(() => import('./LiveWins'), {
  ssr: false,
});

/* eslint-disable-next-line */
export interface CasinoPageProps {}

const StyledCasinoPage = styled(Stack)`
  color: white;
`;

const TabTitle = styled((props: any) => (
  <Stack direction={'row'} alignItems={'center'} gap={0.5} {...props}></Stack>
))(() => {
  return {};
});

export function CasinoPage(props: CasinoPageProps) {
  const { t } = useTranslation();

  const loggedIn = useAtomValue(isLoggedIn);

  const [{ list }, updateGameList] = useAtom(gameListSelector);
  const [, updateUserInfo] = useUpdateUserInfoAsync();
  const [tab, setTab] = React.useState<string>('lobby');

  useEffect(() => {
    if (isLoggedIn) {
      updateUserInfo([
        UPDATE_USER_INFO_MODE.CASINO_USER_FAVORITES,
        UPDATE_USER_INFO_MODE.CASINO_USER_RECENT_PLAYED,
      ]);
    }
  }, [isLoggedIn, updateUserInfo]);

  const [filter, setFilter] = React.useState<{
    provider?: string;
    sortBy?: SortByEnum;
    searchValue?: string;
  }>({});

  const favorites = queryClient.getQueryData<any>(
    queryKeysFactory.casino.queryFavoriteGames(),
  );

  const recentPlayed = queryClient.getQueryData<any>(
    queryKeysFactory.casino.queryUserFootprint(),
  );

  // useMount(() => {
  //   queryClient.refetchQueries(queryKeysFactory.casino.queryUserFootprint());
  // });

  const casinoCategorizedGames = [
    {
      title: t('Slots'),
      titleIcon: <CasinoSlots fontSize={'30'} />,
      name: 'slots',
      games: list.filter(slotsGamesFilter),
    },
    {
      title: t('Live Casino'),
      titleIcon: <CasinoLive fontSize={'20'} />,
      name: 'live-casino',
      games: list.filter(liveCasinoGamesFilter).sort((ga, gb) => {
        if (ga.category.includes('Roulette')) {
          return -1;
        }
        return 0;
      }),
      hidden: isTelegramMiniApp,
    },
    {
      title: t('Blackjack'),
      titleIcon: <CasinoBlackjack fontSize={'20'} />,
      name: 'blackjack',
      games: list.filter(blackjackGamesFilter),
      hidden: isTelegramMiniApp,
    },
    {
      title: t('Originals'),
      titleIcon: <CasinoOriginals fontSize={'20'} />,
      name: 'originals',
      games: list.filter(originalGamesFilter),
    },
    {
      title: t('Table Games'),
      titleIcon: <CasinoTableGames fontSize={'20'} />,
      name: 'table-games',
      games: list.filter(tableGamesFilter),
    },

    {
      title: t('Baccarat'),
      titleIcon: <CasinoBaccarat fontSize={'20'} />,
      name: 'baccarat',
      games: list.filter(baccaratGamesFilter),
      hidden: true,
    },
    {
      title: t('Roulette'),
      titleIcon: <CasinoRoulette fontSize={'20'} />,
      name: 'roulette',
      games: list.filter(rouletteGamesFilter),
      hidden: isTelegramMiniApp,
    },
  ];

  const { theme } = useTheme();

  const titleProps = {
    sx: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.white,
    },
  };

  // @ts-ignore
  const categoryTabs: IWrappedTabChild[] = [
    {
      title: (
        <TabTitle>
          <Home />
          <Box component={'span'}>{t('Lobby')}</Box>
        </TabTitle>
      ),
      name: 'lobby',
      content: (
        <Stack gap={4}>
          {list.length ? (
            <Stack gap={2}>
              {/* <GameCardCarousel
                horizontal
                games={list.slice(0, 20)}
                title={
                  <Typography {...titleProps}>
                    <CasinoRelease fontSize={'small'} />
                    <span>{t('New Releases')}</span>
                  </Typography>
                }
              ></GameCardCarousel>
              {favorites?.pages?.length && !isTelegramMiniApp && loggedIn ? (
                <GameCardCarousel
                  horizontal
                  games={mapFavoriteOrRecentToLocal(favorites, list, 20)}
                  title={
                    <Typography {...titleProps}>
                      <CasinoFavorites fontSize={'small'} />
                      <span>{t('My favorites')}</span>
                    </Typography>
                  }
                  viewAllLink={'/casino/favorites'}
                ></GameCardCarousel>
              ) : null} */}
              {recentPlayed?.pages?.length && loggedIn ? (
                <GameCardCarousel
                  horizontal
                  {...titleProps}
                  games={mapFavoriteOrRecentToLocal(recentPlayed, list, 20)}
                  title={
                    <Typography {...titleProps}>
                      <CasinoRecent fontSize={'small'} />
                      <span>{t('Recent')}</span>
                    </Typography>
                  }
                  viewAllLink={'/casino/recent'}
                ></GameCardCarousel>
              ) : null}
              {casinoCategorizedGames
                .filter((c) => !c?.hidden)
                .map((config) => (
                  <GameCardCarousel
                    horizontal
                    title={
                      <Typography {...titleProps}>
                        {config.titleIcon}
                        <span>{config.title}</span>
                      </Typography>
                    }
                    games={config.games}
                    viewAllLink={`/casino/${config.name}`}
                    key={config.name}
                  />
                ))}
            </Stack>
          ) : (
            <Stack alignItems={'center'}>
              <CircularProgress size={24} />
            </Stack>
          )}
        </Stack>
      ),
    },
    {
      title: (
        <TabTitle>
          <Favorite />
          <Box component={'span'}>{t('Favorites')}</Box>
        </TabTitle>
      ),
      name: 'favorites',
      disabled: !loggedIn,
      content: (
        <UserGameList
          queryKey={queryKeysFactory.casino.queryFavoriteGames() as any}
          queryFn={fetchFavouriteGames}
          noDataText={t('You have no favorite games yet.')}
          title={
            <Typography {...titleProps}>
              <CasinoFavorites fontSize={'small'} />
              <span>{t('My favorites')}</span>
            </Typography>
          }
        />
      ),
      hidden: isTelegramMiniApp || !loggedIn,
    },
    {
      title: (
        <TabTitle>
          <Restore />
          <Box component={'span'}>{t('Recent')}</Box>
        </TabTitle>
      ),
      name: 'recent',
      disabled: !loggedIn,
      hidden: !loggedIn,
      content: (
        <UserGameList
          queryKey={queryKeysFactory.casino.queryUserFootprint() as any}
          queryFn={fetchGameVisitList}
          noDataText={t('You have not played any games yet.')}
          title={
            <TabTitle>
              <Restore />
              <Box component={'span'}>{t('Recent')}</Box>
            </TabTitle>
          }
        />
      ),
    },
    ...casinoCategorizedGames.map((config) => ({
      ...config,
      title: (
        <TabTitle>
          {config.titleIcon}
          {config.title}
        </TabTitle>
      ),
      content: (
        <GameCardCarousel
          title={
            <Typography {...titleProps}>
              {config.titleIcon}
              <span>{config.title}</span>
            </Typography>
          }
          games={config.games}
        />
      ),
    })),
  ];

  const hasFilterValue = Object.values(filter).join('') ? true : false;

  const searchFilterList = hasFilterValue
    ? list
        .filter((item) => {
          const nameCondition = filter.searchValue
            ? item.name
                .toLowerCase()
                .includes(filter.searchValue.toLowerCase()) ||
              item?.slug
                .toLowerCase()
                .includes(filter.searchValue.toLowerCase())
            : true;
          const productCondition = filter.provider
            ? item.product === filter.provider
            : true;
          return nameCondition && productCondition;
        })
        .sort((gameA, gameB) => {
          const { sortBy } = filter;
          if (sortBy && String.prototype.localeCompare) {
            if (sortBy === SortByEnum.ALPHABETICAL_A_Z) {
              return gameA.name.localeCompare(gameB.name, undefined, {
                numeric: true,
              });
            } else if (sortBy === SortByEnum.ALPHABETICAL_Z_A) {
              return gameB.name.localeCompare(gameA.name, undefined, {
                numeric: true,
              });
            }
          } else {
            return undefined;
          }
        })
    : null;

  const gameSearchResultNode = hasFilterValue ? (
    searchFilterList.length ? (
      <GameCardCarousel
        games={searchFilterList}
        sxs={{
          wrapperSX: {
            maxHeight: 'auto',
          },
        }}
      />
    ) : (
      <Box textAlign={'center'}>No games found!</Box>
    )
  ) : null;

  // @Tmp: remove this when we have all the games
  const excludeProducts = [
    // 'Bombay Live',
    'Turbo Games',
    'Turbogames',
    'BGaming',
    'Fugaso',
    'Fugas',
    'NetEnt',
  ];

  const productList = useMemo(() => {
    return [
      ...new Set(
        list
          .map((item) => item.product)
          .filter((item) => !excludeProducts.includes(item)),
      ),
    ];
  }, [list]);

  const titleText = useCasinoTitle(tab);

  const descriptionText = useCasinoDescription(tab);

  return (
    <>
      <Head>
        {renderMetaTitle(titleText)}
        {renderMetaDescription(descriptionText)}
      </Head>
      <StyledCasinoPage>
        {<HomepageBannerBox />}
        {/* {loggedIn ? <CasinoHomepageBanners /> : null} */}
        <WrappedTabs
          sxs={{
            wrapperSX: {
              marginTop: [4, 5],
            },
          }}
          currentTab={tab}
          onChange={(e, tab) => {
            setTab(tab);
            setFilter({});
          }}
          slots={{
            beforeContent: (currentTab) => {
              return (
                <Box mt={2} mb={2}>
                  <GameSearchWithFilter
                    setFilter={setFilter}
                    filter={filter}
                    productList={productList}
                    enabledFilters={{
                      provider: currentTab !== 'lobby',
                      sortBy: currentTab !== 'lobby',
                    }}
                  />
                </Box>
              );
            },
            content: gameSearchResultNode,
          }}
          // label={'tab'}
          basePath="/casino"
          scrollToTopDistanceWhenChangeTab={APP_FIXED_HEADER_HEIGHT + 20}
        >
          {categoryTabs}
        </WrappedTabs>
        <CasinoPageLiveWins />
        <GameProviders />
        <AdditionText currentTab={tab} />
      </StyledCasinoPage>
    </>
  );
}

export default CasinoPage;
